import React, { useState ,useEffect} from "react"
import { requetteSearch } from "./constants";
import axios from "axios";
import './YoutubeAppPlayList.scss'


const TestYoutubeforNdaliChannel= () => {
  const [videos,setVideos]=useState(undefined)
  
  
  useEffect(() => {
    const LancerRequette=() =>{
      axios.get(requetteSearch).then((resp)=>{
        //console.log("retour",resp.data)
        let ListVideo = resp.data.items.filter(item => item.id.kind==="youtube#video");
        console.log("ListVideo",ListVideo)
        setVideos(ListVideo)
      })
    }
    LancerRequette();
  }, [])
  return (
    <>
     <div className="row d-flex flex-row align-items-center justify-content-between">
        <div>
        <h2 className="title my-10">Vidéos en ligne</h2>
            {/* <button onClick={LancerRequette}>Lancer</button> */}
        </div>
        <div>
        {
               videos&& videos.map((item)=>{
                    const {id,snippet={}}=item;
                    const {title={} }=snippet;
                    //const {medium= {}}=thumbnails
                    return (
                      <>
                        <div key= {id.videoId} className="col-sm-6 col-md-6  " >
                                <iframe width="583" height="328" src={`https://www.youtube.com/embed/${id.videoId}`}
                                title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                </iframe>
                        </div>
                        
                      </>
                    )
                })
            }
            
        </div>

     </div>
    </>
  )
}

export default TestYoutubeforNdaliChannel