import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import Player from './components/video_player/Player';
//import NavBar from './components/BootStrapMenu/navbar';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from './Ndali/Site/components/MainRoutes';
import { Provider } from 'react-redux'
import { store } from './zReducer/store'

//import PlateForme from './PlateForme/PlateForme';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
  <BrowserRouter>
    <MainRoutes/>
  </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
