import React from 'react'
import {   Navbar,  Nav, NavDropdown} from "react-bootstrap";
import {Link} from 'react-router-dom'
import Entete from '../../Site/components/entete';
import AdminMenuBar from '../AdminMenuBar';
import AdminListArticles from '../Contents/AdminListArticles';
import './AdminHomePage.css'
import ProtectContent from '../../helpers/ProtectContent';
const AdminHomePage
 = () => {
  return (
    <div className='container'>
        <AdminListArticles/>
    </div>
  )
}

export default ProtectContent(AdminHomePage)
