export const YOUTUBE_PLAYLIST_ITEMS_API="https://www.googleapis.com/youtube/v3/playlistItems"
export const YOUTUBE_API_KEY="AIzaSyDPk5Uj2ntTRZpXrgQ2SeA7frzTyK8D66g";
//export const YOUTUBE_CHANNEL_ID='UCy9RwHQNS4ubcuakvJIPm4A' //c'est le channel_id de ma chaine
export const YOUTUBE_CHANNEL_ID='UCrbalwfIMvkMrbOqgVkkhQg';//channel_id du diocèse de NDali
export const YOUTUBE_PART='snippet'
export const BASE_API_URL='https://www.googleapis.com/youtube/v3/'
export const servSearch='search'

//https://www.googleapis.com/youtube/v3/search?key=AIzaSyDPk5Uj2ntTRZpXrgQ2SeA7frzTyK8D66g&channelId=UCrbalwfIMvkMrbOqgVkkhQg&part=snippet,id&order=date

export const requetteSearch=`${BASE_API_URL}${servSearch}?key=${YOUTUBE_API_KEY}&channelId=${YOUTUBE_CHANNEL_ID}&part=${YOUTUBE_PART},id&order=date`

