import axios from "axios";
import React,{ useState,useEffect } from "react";
import { ClientUrl } from "../helpers/constantes";
import { ServerUrl } from "../helpers/constantes";
import Confirm from "../helpers/ConfirmService/Confirm";
const ViewImagesSlides =()=>{
  const [ListImages,SetListImages]=useState([])
  const [nbeRefresh,SetnbeRefresh]=useState(0)
 
  useEffect(() => {
    async function fetchData() {
     const {data}=await axios.post(`${ServerUrl}/api/slide/all`)
     SetListImages(data)
    }
    fetchData();
  }, [nbeRefresh])

  const OnSupprimer= async (image)=>{
    const result = await Confirm.show({
      title:"ATTENTION Veillez confirmer",
      message: 'Voulez-vous vraiment supprimer cette image ?'
    });
    if (result) {
      await axios.post(`${ServerUrl}/api/slide/del`, image)
      SetnbeRefresh(nbeRefresh+1)
    }
  }
return (
        <div className="row d-flex flex-row">
        <p className="text-light bg-success text-center"> Images déjà sur le serveur</p>
         {
          ListImages.map((image,index)=>{
              const lefile=ClientUrl+"/"+image.fichier
             // console.log(lefile)
          return (
            <div className="col-sm-6 col-md-4" key={image.id}>
            <div className="Card">
                <div className="card shadow">
                    <img src= {lefile} alt={image.description}/>
                </div>
            </div>
              <div className="w-100 px-4 py-5 d-flex flex-row flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    {image.description}
                  </div>
                  <button className="btn btn-danger" onClick={()=>OnSupprimer(image)}
                  >Supprimer</button>
                </div>  
          </div>
            )
        })
        } 
        </div>
)
}
export default  ViewImagesSlides