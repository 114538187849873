import React from 'react'
import { useParams } from 'react-router-dom';
import BlogArtCat from '../components/BlogArtCat';
import Entete from '../components/entete';
import MenuBar from '../components/MenuBar';
const ArticlesForOneCategory = () => {
    const {id} = useParams();
    console.log("useParams " ,useParams())
return (
    <div className='container'>
        <Entete/>
        <MenuBar/>
        <BlogArtCat catid={id} ArticlesPerPage={9}/>
    </div>
  )
}

export default ArticlesForOneCategory