import React , { useEffect, useState}from 'react'
import './EspaceCatechisme.scss';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { ServerUrl } from '../../../helpers/constantes';
const EspaceCatechisme =()=>{
    const catid=130;
    const nbre=3;
    const [listArticles, setListArticles]=useState(undefined)
    const navigate=useNavigate()
    
    const Show=async (art)=>{
      //incrémente hits
      let param={id:art.id}
      await axios.post(`${ServerUrl}/api/contents/incrementhits`,param)
      navigate(
        '/article',{state:{item:art}}
      )
   }
    useEffect(() => {
        async function fetchData() {
          const param={
            catid:catid,
            nbre:nbre
        }
          //console.log("EspaceCatechisme useEffect param====",param)
          const {data}=await axios.post(`${ServerUrl}/api/contents/getcontentcatlimit`,param)

          setListArticles(data)
          //console.log("data EspaceCatechisme",data)
        }
        fetchData();
      }, [])
    return (
        <>
           <p className='titte'>Catéchisme</p>
           {
            listArticles && listArticles.map((article)=> {
              return (
                <div  key ={article.id}>
                  <div className="w-100 px-2 py-2 d-flex flex-row flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-row align-items-center">
                       <strong>{article.title}</strong> 
                      </div>
                  </div> 
                  <div className="d-flex flex-row justify-content-center"> 
                   <button className='btn btn-success mb-2' onClick={()=>Show(article)}>Lire la suite...</button>
                  </div>
                </div>

              )
            })
            
           }
        </>
    )
}
export default EspaceCatechisme