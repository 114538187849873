import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons'
import Entete from '../components/entete';
import MenuBar from '../components/MenuBar';
import { ServerUrl } from '../../helpers/constantes';
import { ClientUrl } from '../../helpers/constantes';
import './ShowArticle.css'
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
function formatDate_DDMMYY(date) {
return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
].join('/');
}  
const ShowArticle = () => {
  const art=useLocation().state.item;
  //console.log(" ShowArticle => art = ",art)
  const navigate=useNavigate()
  let ImagesInfo =''
  if (art.images) ImagesInfo=JSON.parse(art.images);
  let lesParties=art.created.split("T");
  console.log("ImagesInfo = ",ImagesInfo)
  let Ladate=lesParties[0]
  let dateTimeParts=Ladate.split("-");
  //console.log("dateTimeParts = ",dateTimeParts)
  dateTimeParts[1]--;
  const dateObject =new Date(dateTimeParts[0], dateTimeParts[1], dateTimeParts[2], dateTimeParts[3]||0, dateTimeParts[4]||0, dateTimeParts[5]||0);

  console.log("art = ",art)
  console.log("dateObject = ",dateObject)
  console.log("formatDate_DDMMYY = ",formatDate_DDMMYY(dateObject))
  let LaDateCreation=formatDate_DDMMYY(dateObject)
  return (
    <div className="container">
        
        <Entete/>
        <MenuBar/>
        <div className='leTitre'> {art.title}</div>
        <div className="d-flex flex-row align-items-center" > 
            <div className="d-flex flex-row  " > 
                <span >
                <i className="fa fa-user infoArt" aria-hidden="true"></i>{art.auteur} 
                <i className="fa fa-eye infoArt" aria-hidden="true"></i> Affichages : {art.hits}
                <i className="fa fa-calendar  infoArt" aria-hidden="true"></i> {LaDateCreation}
                </span>
            </div>
            <div className="mx-5" > 
            <button className='btn btn-success mb-2' onClick={()=>navigate(-1) }>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
                Retour
            </button>
           
            </div>
        </div>

        <i><div className='introtext'
            dangerouslySetInnerHTML={{
                __html: art.introtext
            }}>
        </div></i>
        <div className='corps'>
            {
            ImagesInfo.image_fulltext ?
            <img className='imgleft img-fluid' src={ `${ClientUrl}/`+ImagesInfo.image_fulltext} alt="red250 red IMG 6937" 
                />
                : null
            }
            <div
                dangerouslySetInnerHTML={{
                    __html: art.fulltext
                }}>
            </div>
        </div>    
    </div>
  )
}

export default ShowArticle