import React, { useEffect, useState } from "react";
import './CardArticles.css'
import CartArticle from "./CartArticle";
import axios from 'axios'
import ReactPaginate from "react-paginate";
import { ServerUrl } from "../../helpers/constantes";
import './BlogArtCat.scss'

const BlogArtCat=({catid,ArticlesPerPage})=>{
    const [Articles, SetArticles]=useState([])
    const [All, SetAll]=useState([])
    const [Categorie, SetCategorie]=useState([])
    const [ArticleByCategorie, SetArticleByCategorie]=useState([])
    
    useEffect(() => {
        async function fetchData() {
          const param={"catid":catid}
          console.log("BlogArtCat useEffect param====",param)
          const {data}=await axios.post(`${ServerUrl}/api/contents/all`,param)
          console.log("BlogArtCat useEffect data====",data)
          SetAll(data)
          SetCategorie(data.TheCat)
          console.log("data.TheCat ",data.TheCat)
          SetArticles(data.Articles)
        }
        fetchData();
      }, [catid])

    
        
    const [page, setPage] = useState(0);
    const totalPages = Math.ceil(Articles.length / ArticlesPerPage);
    const numberOfArticleVistited = page * ArticlesPerPage;

    const displayArticles = Articles
    .slice(
      numberOfArticleVistited,
      numberOfArticleVistited + ArticlesPerPage
    )
    .map((art) => {
      return (
        <CartArticle key={art.id} article={art}/>
      );
    });

    const changePage = ({ selected }) => {
      setPage(selected);
    };
    
    console.log('Categorie ',Categorie)
    
    return (
      <div className="container mb-5">
          {Categorie[0]?.title &&<div className="catTitle">{Categorie[0].title}</div>}
          <div className="row d-flex flex-row py-2">
          {displayArticles}
          </div>
          <ReactPaginate
              previousLabel={"Prec"}
              nextLabel={"Suiv →"}
              pageCount={totalPages}
              onPageChange={changePage}
              containerClassName={"navigationButtons"}
              previousLinkClassName={"previousButton"}
              nextLinkClassName={"nextButton"}
              disabledClassName={"navigationDisabled"}
              activeClassName={"navigationActive"}
            />
        </div> 
    )

}
export default BlogArtCat