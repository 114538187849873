import React,{useEffect} from 'react'
import { useState } from "react"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import { useForm,Controller} from "react-hook-form";
import axios from "axios";
import { ServerUrl } from '../../../helpers/constantes';

const ImageForm = ({artId,name,titre,image}) => {
    const { register, control, handleSubmit, resetField, formState, watch } = useForm();
    const { errors } = formState;
    const [TheFile,setTheFile]=useState(null)
    const [initialValue,setInitialValue]=useState("")
    const [nbeRefresh,SetnbeRefresh]=useState(0) 
    
    
   
    useEffect(() => {
        // console.log("currentUser ",JSON.parse(currentUser))
        console.log(" artId : ",artId);
        setInitialValue(image)
        }, [initialValue])
   
    const onSubmit = async (data) => {
        console.log("name=",name)
        console.log("artId=",artId)
        var formData = new FormData();
        console.log("Données soumises= ",data)      
        formData.append("fichier",data[`${name}`][0]);
        formData.append("typeImage",`${name}`);
        formData.append("artId",`${artId}`);
        axios.post(`${ServerUrl}/artImages`, formData, {
            headers: {
             "Content-Type": "multipart/form-data",
            // 'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            },
          })
        .then(() => { 
            console.log("Fin envoie fichier : ",data[`${name}`][0].name)
            SetnbeRefresh(nbeRefresh+1) 
            setInitialValue(data[`${name}`][0].name)
            })


        

        
        /* if (name==="image_intro"){
            LeFichier=data.image_intro[0]
        } else if (name==="image_fulltext") {
            LeFichier=data.image_fulltext[0]    
        
        let ToSend={
            name:`${name}`,
            file:data[`${name}`][0]
        }
        console.log("OnSbmit data ToSend",ToSend)
        console.log("Le Fichier:",ToSend.file) 
        } */

    }
    const resetInputFile=()=> {
        console.log('resetInputFile')
        resetField(`${name}`)
    }
    const OnCancel=()=> {
        console.log('OnCancel')
        resetField(`${name}`)
    }
    const handleFileChange = event => {
        const fileObj =/* event.target.files && */event.target.files[0];
        setTheFile(event.target.files[0]) 
        console.log('fileObj is', fileObj);
        if (!fileObj) {return;}
        
    }
  return (
    <div className='container'>
        <div className="row upload ">
        <form  onSubmit={handleSubmit(onSubmit)}>
                    <h3>{titre}</h3>
                    <div className="form-group">
                     <div>Valeur Initiale : <strong>{initialValue}</strong></div >
                        <input name={`${name}`}
                            {...register(`${name}`,
                                    { required: "Veuillez choisir un fichier",                                                     
                                    validate:async(value)=>{
                                        console.log("value",value[0].name)
                                        //var un={filename:"images/slides/"+value[0].name}
                                        //let ex=await fileExist(un)
                                        /* console.log ("ex ?",ex)
                                        console.log ("ex!=1 ?",ex!=1)
                                        console.log ("ex!==1 ?",ex!==1) */
                                        //return ex!=1
                                        return true
                                    }
                                    },
                                    )
                            } 
                            type="file" accept="image/*" onChange={handleFileChange}
                        />
                        <button type="button" className="btn btn-danger" onClick={resetInputFile}> <DeleteForeverIcon 
                              
                        /> Supprimer</button>
                    </div>
                    {errors[`${name}`] && <small className="text-danger">{errors[`${name}`].message}</small>} 
                    {errors[`${name}`] && errors[`${name}`].type === "validate" && 
                        (<small className="text-danger">Ce fichier a déjà été choisi</small> )
                    }
                    <div className="form-group button">
                        <button className="btn btn-success" onClick={onSubmit}> <CloudUploadIcon/> Vailder</button>
                        <button className="btn btn-warning" type="button" onClick={OnCancel}> <CancelIcon/> Annuler</button>
                    </div>
         </form>
        </div>
        <style jsx="true">{`
        .upload {
            margin-bottom: 30px;
        }
        .button{
            margin-right: 40px;;
        }

        
      `}</style>
    </div>
  )
}

export default ImageForm