import React from "react";
import {   Navbar,  Nav, NavDropdown} from "react-bootstrap";
import {Link} from 'react-router-dom'
import './mycolors.scss'
import MenuItems from '../MenuItems.json';
const MenuBar =()=>{
    return (
        <Navbar className="naBarColor" expand="lg" variant="light">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              <Nav.Link as={Link} to ={`/`}>Accueil</Nav.Link>
                {
                  MenuItems.map((item)=>{
                    if (item.typeMenu==="MainMenu")
                    return (
                      <>
                      {
                        item.submenu ?
                        <NavDropdown title={item.title} id="basic-nav-dropdown" key={item.id}>
                          {
                            item.submenu.map((sub2)=>{
                              return (
                                 <>
                                 {
                                   sub2.submenu ?
                                    <NavDropdown className ='niveauDeux' title={sub2.title} id="basic-nav-dropdown" key={sub2.CatId}>
                                    {
                                      sub2.submenu.map((sub3)=>{
                                        return(
                                          <NavDropdown.Item as={Link} to ={`/men/${sub3.slug}/${sub3.CatId}`} key={sub3.id}>{sub3.title}</NavDropdown.Item>
                                        )
                                      })
                                    }
                                    </NavDropdown>
                                    :
                                    <>
                                      <NavDropdown.Item as={Link} to ={`/men/${sub2.slug}/${sub2.CatId}`}  key={sub2.id}>{sub2.title}</NavDropdown.Item>
                                    </>
                                 }
                                  
                                 </>

                                
                              )
                            })
                          }
                        </NavDropdown>
                        : <Nav.Link as={Link} to ={`/men/${item.slug}/${item.CatId}`} key={item.id}>{item.title}</Nav.Link>
                      }
                      </>
                    )
                  })
                }
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      
    )
}
export default MenuBar