
import React from "react";
import {useNavigate} from 'react-router-dom'
import { ClientUrl } from "../../helpers/constantes";
import { ServerUrl } from "../../helpers/constantes";
import axios from "axios";


const CartArticle =({article})=>{
   let ImagesInfo =''
   const navigate=useNavigate()
  
   if (article.images) ImagesInfo=JSON.parse(article.images);
   //image_fulltext
   
  const Show=async ()=>{
     //incrémente hits
     let param={id:article.id}
     await axios.post(`${ServerUrl}/api/contents/incrementhits`,param)
     navigate(
       '/article',{state:{item:article}}
     )
  }
  //console.log("ImagesInfo ",ImagesInfo);
 //const url = req.protocol + '://' + req.get('host')
   return (
    <>
    <div className="col-sm-6 col-md-4" >
        <div className="Card">
        {  ImagesInfo.image_intro ?
            <>
            <div className="card shadow">
                <img src= { `${ClientUrl}/`+ImagesInfo.image_intro} alt="im1"/>
            </div>
            </> :null
            
        }
        </div>
        <div className="w-100 px-2 py-2 d-flex flex-row flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              {article.title}
            </div>
        </div>  
        <div className="d-flex flex-row justify-content-center"> 
          
          <button className='btn btn-success mb-2' onClick={()=>Show()}>Lire la suite...</button>
          </div>
      </div>
    </>
   )

}
export default CartArticle