import React from 'react'
import {   Navbar,  Nav, NavDropdown} from "react-bootstrap";
import {Link} from 'react-router-dom'
import ProtectContent from '../helpers/ProtectContent';
const AdminMenuBar = () => {
  return (
    <div >
    <Navbar className="naBarColor" expand="lg" variant="light">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            <Nav.Link as={Link} to ={`/admin`}>Accueil</Nav.Link>
            <NavDropdown title={"Utilisateurs"} id="basic-nav-dropdown" >
                 <NavDropdown.Item as={Link} to ={`/utilisateur-ajouter`}>Ajouter</NavDropdown.Item>
                 <NavDropdown.Item as={Link} to ={`/utilisateur-chpwd`}>Modifier son mot de passe</NavDropdown.Item>
                 <NavDropdown.Item as={Link} to ={`/utilisateur-deconnecter`}>Déconnecter</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to ={`/admlst`}>Articles</Nav.Link>
            <Nav.Link as={Link} to ={`/setslides`}>Les Slides</Nav.Link>
        </Nav>
        </Navbar.Collapse>
    </Navbar>
</div>
  )
}

export default ProtectContent(AdminMenuBar)