import React from 'react'
import { Routes,Route } from 'react-router-dom';
import Home from '../Pages/Home';
import SocialFollow from './Social/SocialFollow';
import ArticlesForOneCategory from '../Pages/ArticlesForOneCategory';
import ShowArticle from '../Pages/ShowArticle';
import AdminListArticles from '../../Admin/Contents/AdminListArticles';
import EditMainForm from '../../Admin/Contents/EditArt/EditMainForm';
import CreateUser from '../Pages/CreateUser';
import AdminHomePage from '../../Admin/home/AdminHomePage';
import UploadSlideShowFilesForm from '../../Admin/UploadSlideShowFilesForm';
import Logout from '../Pages/Logout';
import ChangePassWord from '../../Admin/ChangePassWord/ChangePassWord';
import TestYoutubeforNdaliChannel from './YoutubePlayList/TestYoutubeforNdaliChannel';
import { OnlineStatusProvider } from '../../Admin/useOnlineStatus';
const MainRoutes = () => {
  return (
    <div >
    <OnlineStatusProvider>
        <Routes >
          <Route path='/' element={<Home/>} />
          <Route path='/social' element={<SocialFollow/>} />
          <Route path="/men/:slug/:id" element={<ArticlesForOneCategory/>} />
          <Route path="article" element={<ShowArticle/>} />
          <Route path="/admlst" element={<AdminListArticles/>} />
          <Route path="/admedit" element={<EditMainForm/>} />
          <Route path="/admin" element={<AdminHomePage/>} />
          <Route path="/signup" element={<CreateUser/>} />
          <Route path="/test" element={<TestYoutubeforNdaliChannel/>} />
          <Route path="/setslides" element={<UploadSlideShowFilesForm/>} />
          <Route path="/utilisateur-ajouter" element={<CreateUser/>} />
          <Route path="/utilisateur-chpwd" element={<ChangePassWord/>} />
          <Route path="/utilisateur-deconnecter" element={<Logout/>} />
        </Routes>
    </OnlineStatusProvider>
  </div>
  )
}

export default MainRoutes