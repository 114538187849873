import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../helpers/Tabs/Tabs';
import { useNavigate } from 'react-router-dom';
import EditArticleForm from './EditArticleForm';
import ImagesChoiseForm from './ImagesChoiseForm';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useDispatch,useSelector} from "react-redux"
import Entete from '../../../Site/components/entete';
import AdminMenuBar from '../../AdminMenuBar';
import './EditMainForm.css'

const EditMainForm = () => {
  const [value, setValue] = React.useState('one');
  const location=useLocation()
  const navigate=useNavigate()
  const article=location.state
  const dispatch=useDispatch();
 /*  console.log("============================")
  console.log("introText",article.introtext)
  console.log("data_corps",article.fulltext)
  console.log("idArticle",article.id)
  console.log("============================") */

  dispatch({type:'SET_ARTICLE_ID',payload:article.id})
  dispatch({type:'SET_DATA_INTO',payload:article.introtext})
  dispatch({type:'SET_DATA_CORPS',payload:article.fulltext})
  dispatch({type:'SET_ARTICLE_TITLE',payload:article.title})
  dispatch({type:'SET_ARTICLE_CATID',payload:article.catid})
 
  console.log("article.id",article.id) 
  const goBack = () => {
    console.log("Ici GoBack ")
    navigate("/admlst")
  }
   const mainTitre = {
      color: "white",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "brown",
      padding: "10px",
      fontFamily: "Arial"
    };
  return (
    <div className='container'>
      <Entete/>
       <AdminMenuBar/>
       <h3 style={mainTitre}>Page d'administration</h3>
      
    <Tabs >
      <div title="Contenu">
          <h3>Textes de l'article</h3>
          <EditArticleForm article={article}/>
        </div>
        <div title="Images">
          <h3>Les Images</h3>
          <ImagesChoiseForm artId = {article.id} images={article.images}/>
        </div>
    </Tabs>
    </div>
 
  )
}

export default EditMainForm