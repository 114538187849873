import { useState } from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ClassicEditor from 'ckeditor5-custom-build';
//import {DecoupledEditor} from '@ckeditor/ckeditor5-build-decoupled-document';
//import {CKFinder} from '@ckeditor/ckeditor5-ckfinder';
import { ServerUrl } from '../helpers/constantes';
import { ClientUrl } from '../helpers/constantes';
import {useDispatch} from "react-redux";


import axios from 'axios';

const TheEditor =({EditorName, TheData,titre})=>{
  const dispatch=useDispatch()

    function uploadAdapter(loader) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              const body = new FormData();
              loader.file.then((file) => {
                body.append("files", file);
                axios.post(`${ServerUrl}/CkEditor-image-2`,body)
                .then((res) => {
                      console.log("res-------",res.data.fichier)
                      resolve({ default: `${ClientUrl}/${res.data.fichier}`});
                })
                .catch((err) => {
                  reject(err);
                })
              });
            });
          }
        };
      }
      function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
      }
    const [TheEditor, setTheEditor] = useState(null);
    const [updatedData, setUpdatedData] = useState(null);
   
    return(
    <>
        <div className='text-center'>{titre}</div>
        <CKEditor
            config={{
                extraPlugins: [uploadPlugin],
                ckfinder: {
                  uploadUrl: `${ServerUrl}/CkEditor-image-2`,
                  options: { resourceType: 'Images' },
                  openerMethod: 'popup'
              },
               toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'subscript',
                    'superscript',
                    'alignment',
                    '|',
                    'fontFamily',
                    'fontSize',
                    'fontColor',
                    'fontBackgroundColor',
                    'highlight',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'link',
                    'imageInsert',
                    'imageUpload',
                    'blockQuote',
                    'insertTable',
                    //'mediaEmbed',
                    'code',
                    'specialCharacters',
                    '|',
                    'undo',
                    'redo',
                    '|',
                    //'CKFinder'
                ],
                shouldNotGroupWhenFull: true,
            }, 
                image: {
                    // image plugin config
                    toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:bloc', 'imageStyle:alignRight' , 'imageStyle:align-block-left' , 'imageStyle:align-block-right' ],
                    styles: [
                      'bloc',
                      'alignLeft',
                      'alignRight',
                      'align-block-left',
                      'align-block-right'
                    ]
                  },//fin image
            }} //fin config
            editor={ ClassicEditor }
            data={TheData}
              onReady={(e) => {
                  console.log(`${EditorName}_onReady.`,e) 
                  setTheEditor(e)}
                }
              onChange={(event, editor) => {
                const data = editor.getData();
                //console.log(`${EditorName}_onChange.`,{ event, editor, data });
                //console.log({  data });
              }}
              onBlur={editor => {
                //console.log(`${EditorName}_OnBlur.`, editor);
                //console.log(`${EditorName}_OnBlur_datas.`, TheEditor.data.get());
                let dt =TheEditor.data.get()
                if (EditorName=="intro") dispatch({type:'SET_DATA_INTO',payload:dt})
                if (EditorName=="corps") dispatch({type:'SET_DATA_CORPS',payload:dt})
              }}
              onFocus={editor => {
               // console.log("Focus.", editor);
              }}
        />
  
    </>
    )
}
export default TheEditor