import React from "react";
import { Carousel } from "react-bootstrap";
import { ClientUrl } from "../../helpers/constantes";
import  './SildeShow.scss';
const SildeShow=({ImagesInfos})=>{
return (
    <Carousel interval={2000}>
        {
            ImagesInfos?.map((info,index)=>{
                const lefile=ClientUrl+"/"+info.fichier
                //console.log('lefile =',lefile)
                return (
                    <Carousel.Item key={info.id} >
                        <img
                        className="d-block w-100"
                        src={lefile}
                        alt={info.description}
                        />
                        <Carousel.Caption>
                        <p>{info.description}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })
        }
    </Carousel>
)}
export default SildeShow;