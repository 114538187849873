import { SET_DATA_INTO, SET_DATA_CORPS ,SET_ARTICLE_ID,SET_ARTICLE_TITLE,SET_ARTICLE_CATID} from './actions';

const reducer = (state = {idArticle: 0, 
                          introText:' ',
                          corpsText:' ', 
                          images:null,
                          title:'',
                          catid:0
                        }, 
                          action
   ) => {
    switch (action.type) {
      case SET_DATA_INTO:
         return { ...state, introText: action.payload };
      
         case SET_DATA_CORPS:
         return { ...state, corpsText: action.payload };

      case SET_ARTICLE_ID:
         return { ...state, idArticle: action.payload };

      case SET_ARTICLE_TITLE:
         return { ...state, title: action.payload };
         
      case SET_ARTICLE_CATID:
         return { ...state, catid: action.payload };

      default:
         return state;
    }
  };
  export default reducer;