import React from "react";
import {Row,Col} from "react-bootstrap";
import vierge from '../../img/Vierge.jpeg';
import diocese from '../../img/diocese_ndali.jpg';
import Mgr from '../../img/Mgr_Martin.jpg'
const Entete=()=>{
    return(
        <>
            
                <Row>
                    <Col xs={3}>
                        <img src={vierge} alt="La Vierge" className="img-fluid "/>
                    </Col>
                    <Col xs={6}>
                        <img src={diocese} alt="logo diocèse" className="img-fluid " />
                    </Col>

                    <Col xs={3}>
                        <img src={Mgr} alt="Eveêque"  className="img-fluid " />
                    </Col>
                </Row>
        </>
    )

}
export default Entete