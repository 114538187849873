import React from 'react';
import LogInForm from '../Site/Pages/LogInForm';
export default function PrivacyHOC(WrappedComponent) {
    return (
        class PrivacyHOC extends React.Component {

            isLoggedIn = () => {
                var isc;
                
               // console.log("currentUser ",currentUser)
                sessionStorage.getItem('currentUser') ? isc= true: isc=false;
               // localStorage.removeItem("tokengestress");
                //console.log('Is Connected  ', isc)
                return isc
            }
            render () {
                return this.isLoggedIn() ?
                    <WrappedComponent {...this.props} /> :<LogInForm/>
            }
        }
    )
};
