import React from 'react';
import OnLineBar from './OnLineBar';
import './EnteteAdminPage.css'

const EnteteAdminPage = () => {

  return (
     <div >
         <div className='d-flex justify-content-between  Entete'>
            <div className='d-flex justify-content-center  ' > <h3 className='text-center mainTitre'>Page d'administration</h3></div>
            <div className='d-flex justify-content-end'><OnLineBar/></div>
         </div>
     </div>
 
  )
}

export default EnteteAdminPage