import React,{useState,useEffect} from 'react'
import TheEditor from '../../TheEditor'
import SaveIcon from "@mui/icons-material/Save"
import {useNavigate} from 'react-router-dom'
import Button from '@mui/material/Button';
import './EditArticleForm.css'
import {useDispatch,useSelector} from "react-redux"
import { ArticleSetData ,SaveNewArticle} from '../../../ApiForDB/contents/contents';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TreeNodeCatSelect from '../TreeNodeCatSelect';
import MenuItems from '../../../Site/MenuItems.json'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel  from '@mui/material/InputLabel';


const EditArticleForm = ({article}) => {
  var titreInto=<h6><strong>Chapeau</strong></h6>
  var titreCorsp=<h6><strong>Texte complet</strong></h6>
  const dispatch=useDispatch();
  const [valueCatSelected, setvalueCatSelected] = useState(undefined); 
  const [titreArticle,setTitreArticle]= useState('');
  const introText=useSelector(state =>state.introText)
  const corpsText=useSelector(state =>state.corpsText)
  const idArticle=useSelector(state =>state.idArticle)
 // const LeTitreArticle=useSelector(state =>state.title)
  //const catidArticle=useSelector(state =>state.catid)
  const navigate=useNavigate()
  
 const SaveArticle =async ()=> {
  dispatch({type:'SET_ARTICLE_TITLE',payload:titreArticle})
      let param={
        introText:introText,
        fulltext:corpsText,
        id:idArticle,
        catid:valueCatSelected,
        title:titreArticle,
      }
      //console.log("SaveArticle =>param ",param)
      //console.log("SaveArticle =>article.id ",article.id)
      //console.log("SaveArticle =>article ",article)
      if (article.id===0 ||typeof article.id === 'undefined') {
        console.log("Save New Article =>article ",article)
        article.fulltext=corpsText;
        article.introtext=introText;
        article.catid=valueCatSelected;
        article.title=titreArticle;
        let tosave=article

        delete tosave['id']

        let ret=await SaveNewArticle(tosave);
        article.id=ret.data.insertId;
        dispatch({type:'SET_ARTICLE_ID',payload:article.id})
        console.log("SaveArticle =>New article.id ",article.id)
      }
      else  ArticleSetData(param);
  }

  const onChangeCatSelected = (newValue) => {
    setvalueCatSelected(newValue)
    dispatch({type:'SET_ARTICLE_CATID',payload:newValue})

    if (newValue!==undefined) {
      console.log("onChangeCatSelected ",newValue) 
    } else  {
      console.log("onChangeCatSelected NON DEFINIE") 
    }
  }
  
  useEffect(() => {
    console.log("UneEffect Called avec article=",article)
    setvalueCatSelected(article.catid);
    setTitreArticle(article.title)
    }, [article])  
  return (
    <div className='container'>
        <div className='alignbutton'>
            <Button color="secondary" startIcon={<ArrowBackIosIcon  />} variant="contained"
                onClick={()=>{ navigate('/admin')}}>
                  Page Préc
            </Button>
            <Button endIcon={<SaveIcon />} 
                variant="contained" onClick={SaveArticle} disabled={valueCatSelected===undefined ||titreArticle.length===0 }
            >Enregistrer</Button>
         </div>
        <div>
            {
             !valueCatSelected &&<div className='text-danger'>  Vous devez choisir une catégorie</div>
            }
            <TreeNodeCatSelect treeData={MenuItems} value={valueCatSelected}
                  onChange={onChangeCatSelected} placeholder={"Catégorie"}
            />


         {/* titre de l'article */}
        </div> 
        <div>
            {
             titreArticle.length===0 &&<div className='text-danger'>Vous devez saisir un titre</div>
            }
            <FormControl fullWidth={true}>
            <InputLabel htmlFor="titre">Titre de l'article</InputLabel>
            <TextField id="titre" /* label="Titre Article" */ variant="outlined"  
              defaultValue={titreArticle} 
              onChange={e => {
                  setTitreArticle(e.target.value)
                }}
              className='my-3'
              value={titreArticle}
            />
            </FormControl>
        </div>
        <TheEditor EditorName={"intro"}titre={titreInto} TheData={article.introtext}/>
        <TheEditor EditorName={"corps"}titre={titreCorsp} TheData={article.fulltext}/>
    </div>
  )
}

export default EditArticleForm