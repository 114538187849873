/*
const IsLocalHost =Boolean(
    window.location.hostname==="localhost" ||
    window.location.hostname==="[::1]" ||
    window.location.hostname.match(
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    )
)

 export const ServerUrl=IsLocalHost ? "http://localhost:3550": "http://167.71.196.245:3550"
export const ClientUrl=IsLocalHost ? "http://localhost:3000":"https://diocesendali.com"
 */
//export const ServerUrl="http://167.71.196.245:3550"
//export const ServerUrl="http://localhost:3550";
export const ServerUrl="https://www.apis.diocesendali.com";
export const ClientUrl="https://diocesendali.com";
//export const ClientUrl="http://localhost:3000";
