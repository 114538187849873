import React, { useState ,useEffect} from 'react'
import MaterialTable , { MTableToolbar } from "material-table";
import { forwardRef } from 'react';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { ThemeProvider, createTheme } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItems from '../../Site/MenuItems.json'
import { AdminListeArticles } from '../../ApiForDB/contents/contents'
//import SearchByCat from './SearchByCat';
//import SelectTreeCat from './SelectTreeCat';
import ShowComponent from '../../Site/components/ShowComponent';
import {useNavigate} from 'react-router-dom'
import TreeNodeCatSelect from './TreeNodeCatSelect';
import Entete from '../../Site/components/entete';
import AdminMenuBar from '../AdminMenuBar';
import ProtectContent from '../../helpers/ProtectContent';
import './AdminListArticles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { delArticle } from '../../ApiForDB/contents/contents';
import Confirm from '../../helpers/ConfirmService/Confirm';
import EnteteAdminPage from '../EnteteAdminPage/EnteteAdminPage';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const AdminListArticles = () => {
  const defaultMaterialTheme = createTheme();
  const navigate=useNavigate() 
  const [nbeRefresh,SetnbeRefresh]=useState(0)
  const [sql,setSql]=useState({sql:"SELECT cont.*, (SELECT COALESCE(users.name,' ') AS auteur FROM z5ixc_users users WHERE users.id = cont.created_by) Auteur, (SELECT COALESCE(cate.title,' ') AS Categorie FROM z5ixc_categories cate WHERE cate.id = cont.catid) AS Categorie FROM z5ixc_content cont ORDER BY cont.created DESC"}) 
  const [valueCatSelected, setvalueCatSelected] = useState(undefined);
 
  const onChangeCatSelected = (newValue) => {
    let condition
    if (newValue===undefined) 
      condition=" "
   else 
      condition=`WHERE cont.catid=${newValue}` 
    let initSql=`SELECT cont.*, (SELECT COALESCE(users.name,' ') AS auteur FROM z5ixc_users users WHERE users.id = cont.created_by) Auteur, (SELECT COALESCE(cate.title,' ') AS Categorie FROM z5ixc_categories cate WHERE cate.id = cont.catid) AS Categorie FROM z5ixc_content cont  ${condition} ORDER BY cont.created DESC ` 
    setSql({sql:initSql})
    console.log('sql',sql)
    console.log('onChange newValue',newValue);
    console.log('condition ',condition);
    console.log('newValue==undefined  ?',newValue===undefined);
    setvalueCatSelected(newValue);
  }

 const OnDelete=async (rowData)=> {
  
  const result = await Confirm.show({
    title:"ATTENTION Veillez confirmer",
    message: 'Voulez-vous vraiment supprimer cet élément ?'
  });
  if (result) 
  {
    delArticle({id:rowData.id})
    SetnbeRefresh(nbeRefresh+1)
  }
   
 }
 const OnAddNewRow=()=> {
  let currentUser=JSON.parse(sessionStorage.getItem('currentUser'))
  var NewArticle={
    title:'',
    introtext:'',
    fulltext:'',
    id:0,
    state:1,
    catid:'',
    created_by:currentUser.id ,
    created :new Date().toISOString().slice(0, 19).replace("T", " "),
    images:'',
    hits:0
  }
  navigate("/admedit",{state:NewArticle})
}
 const OnEditRow=(rowData)=> {
   console.log("OnEditRow=>rowData ",rowData)
   navigate("/admedit",{state:rowData})
}
 const [ListArt,setListArt]=useState([])
 const [selectedRow, setSelectedRow] = useState(null);

 const [ModalDialogEtat,ChangeModalDialogEtat]=useState(false);

    useEffect(() => {
        async function fetchData() {
         // console.log("BlogArtCat useEffect param====",sql)
          const {data}=await AdminListeArticles(sql)
          //console.log("useEffect AdminListArticles data====",data)
          setListArt(data)
        }
        
        fetchData();
      }, [sql,nbeRefresh])
  
    const AddButton=()  => {
    return (
      
      <Button variant="contained" color="success"   size="small" className='pe-4'
      onClick={OnAddNewRow}>
        <FontAwesomeIcon icon={faNewspaper} className='me-3'/>
        Ajouter
      </Button>
     
    )
  }

  const actions=[
      {
          icon: () => <DeleteIcon/>,
          tooltip: 'Supprimer',
          onClick: (event, rowData) => OnDelete(rowData)
        },
        {
          icon: () => <Edit/>,
          tooltip: 'Modifier',
          onClick: (event, rowData) => OnEditRow(rowData)
          },
        
    
    ] 
  
    const  localization={
    body: {
        emptyDataSourceMessage: "Pas d'enregistreent à afficher",
        addTooltip: 'Ajouter',
        deleteTooltip: 'Supprimer',
        editTooltip: 'Editer',
        filterRow: {
            filterTooltip: 'Filtrer'
        },
        editRow: {
            deleteText: 'Voulez-vous supprimer cette ligne?',
            cancelTooltip: 'Annuler',
            saveTooltip: 'Enregistrer'
        }
    },
    grouping: {
        placeholder: "Tirer l'entête ...",
        groupedBy: 'Grouper par:'
    },
    header: {
        actions: 'Actions'
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} de {count}',
        labelRowsSelect: 'lignes',
        labelRowsPerPage: 'lignes par page:',
        firstAriaLabel: 'Première page',
        firstTooltip: 'Première page',
        previousAriaLabel: 'Page précédente',
        previousTooltip: 'Page précédente',
        nextAriaLabel: 'Page suivante',
        nextTooltip: 'Page suivante',
        lastAriaLabel: 'Dernière page',
        lastTooltip: 'Dernière page'
    },
    toolbar: {
        addRemoveColumns: 'Ajouter ou supprimer des colonnes',
        nRowsSelected: '{0} ligne(s) sélectionée(s)',
        showColumnsTitle: 'Voir les colonnes',
        showColumnsAriaLabel: 'Voir les colonnes',
        exportTitle: 'Exporter',
        exportAriaLabel: 'Exporter',
        exportName: 'Exporter en CSV',
        searchTooltip: 'Chercher',
        searchPlaceholder: 'Chercher'
    }
}
const options={
  exportButton: true,
  pageSize: 10,
  pageSizeOptions: [10, 20, 50],
  rowStyle: rowData => ({
    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
  }),
  headerStyle: {
      position: "sticky",
      top: "0",
      backgroundColor:'red',
      fontWeight: 'bold',
      color: 'white',
    },
  maxBodyHeight: "500px",
  
  
}
  const columns=[
    {title: "id",field: "id",  editable:'never'},
    {title: "Titre", field: "title"},
    {title: "Etat", field: "state",
      editComponent: (props) => {
        return (
          <input
            type="checkbox"
            checked={Boolean(props.value)}
            onChange={(e) =>{
              console.log("props",props);
              console.log("e.target.checked",e.target.checked);
              props.onChange(Boolean(e.target.checked))
            } }
          />
        );
      },
      render: (rowdata) => {
          return (
          <input type="checkbox" checked={Boolean(rowdata.state)} readOnly /> 
          )
      }
    },
    {title: "Date", field: "created",type: 'date'},
    {title: "Categorie", field: "Categorie"},
    {title: "Auteur", field: "Auteur"},

  ]
  const mainTitre = {
    color: "white",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "brown",
    padding: "10px",
    fontFamily: "Arial"
  };
    return (
    <>
        <div className='container'>
          <Entete/>
          <AdminMenuBar/>
          <EnteteAdminPage/>

          <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            data={ListArt}
            title="Liste des articles"
            localization={localization}
            options={options}
            actions={actions}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            components={{
              Toolbar: props => (
                  <div style={{ backgroundColor: 'white' }}>
                      <MTableToolbar {...props} />
                      <div className='toolbar'>
                        
                        <TreeNodeCatSelect treeData={MenuItems} value={valueCatSelected}
                           onChange={onChangeCatSelected} placeholder={"Filtrer par catégorie"}
                        /> 
                        < AddButton/>
                      </div>
                      <div>
                      
                      </div>
                        
                      
                      
                  </div>
              )
          }}
            />
          </ThemeProvider>
          <ShowComponent title={"Modifier la valeur d'un indicateur"} open={ModalDialogEtat} setOpen={ChangeModalDialogEtat}>  
          </ShowComponent>
          
         </div>
    </>
    
  )
}

export default ProtectContent(AdminListArticles)