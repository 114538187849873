import React ,{useRef,useState,useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { TextField,InputAdornment, IconButton,Button,Grid } from '@mui/material';
import { UserExists, insertUser,SeConnecter} from '../../ApiForDB/user.api';
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoginIcon from '@mui/icons-material/Login';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Entete from '../components/entete'
import AdminMenuBar from '../../Admin/AdminMenuBar';
import ProtectContent from '../../helpers/ProtectContent';
import EnteteAdminPage from '../../Admin/EnteteAdminPage/EnteteAdminPage';
import axios from 'axios';
import './CreateUser.css'

const CreateUser = () => {
    const naviguer=useNavigate()
    const UserInfo=useRef({}) 
    const { handleSubmit,reset, formState: { errors },control,   } = useForm({mode:'onTouched'}); 
    const onSubmit=async data => {
        console.log("Le formulaire est validé. ",data)
        const date = new Date().toISOString().slice(0, 19).replace("T", " ");
        data.id=null
        data.registerDate=date
        data.lastvisitDate=date
        data.lastResetTime=date
        insertUser(data)


    }
    const [showPassword, setShowPassword] = useState(false);
    const handleClick = () => {
        setShowPassword(prev => !prev);
      }
      const mainTitre = {
        color: "white",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "brown",
        padding: "10px",
        fontFamily: "Arial"
      };  
    return (
        <div className='container'>
         <Entete/>
        <AdminMenuBar/>
        <EnteteAdminPage/> 
        <form onSubmit={handleSubmit(onSubmit)}> 
            <Grid container  direction="column" justifyContent="center" alignItems="center">
                <Grid container  direction="row" justifyContent="center" alignItems="center">
                     <h3 className='border-b text-center mb-3 pb-3 font-bold' >Enregistrement</h3>
                </Grid >
                <Grid container item xs={12}  direction="column" justifyContent="center" alignItems="center">
                <div className='margebas' ><Controller
                    name="name"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur' ,
                        
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: {  isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField

                        label={ <span style={{ color: "#0D2801" }}> <PersonIcon /> Nom et Prénom *</span>}
                        onChange={onChange} value={value}
                        onBlur={onBlur}
                        
                        />
                    )}
                /></div >
                
                    <Grid >
                        {errors.name && <small className="text-danger">{errors.name.message}</small>} 
                    </Grid>
                </Grid> 
                <Grid container item xs={12}  direction="column" justifyContent="center" alignItems="center">
                <div className='margebas'><Controller
                    name="username"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur' ,
                        
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: {  isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }}> <AccountCircleIcon/> Identifiant *</span>}
                        onChange={onChange} value={value}
                        onBlur={onBlur}
                        
                        />
                    )}
                /></div >
                
                    <Grid >
                        {errors.username && <small className="text-danger">{errors.username.message}</small>} 
                    </Grid>
                </Grid> 
                <Grid container item xs={12}  direction="column" justifyContent="center" alignItems="center">
                <div className='margebas' ><Controller
                    name="email"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur' ,
                        validate:async(value)=>{
                            let resultat=await UserExists(value)
                             return resultat === 0
                        }
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: {  isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }}> <MailOutlineIcon/> Email * </span>}
                        onChange={onChange} value={value}
                        onBlur={onBlur}
                        
                        />
                    )}
                /></div >
                
                    <Grid >
                        {errors.email && <small className="text-danger">{errors.email.message}</small>} 
                        {errors.email && errors.email.type === "validate" && (
                            <div className="text-danger">Ce Email existe déjà</div>
                        )
                        }
                    </Grid>
                </Grid> 
                <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center">
                <div className='margebas' >
                <Controller
                    name="password"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur',
                        /* validate:(value)=>{
                            if (UserInfo.current.exist )
                            {
                                return (UserInfo.current.item[0].Userpwdl===value)
                            }
                        } */
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }} ><VpnKeyIcon/>Mot de passe *</span>}
                        onChange={onChange} value={value}
                        type={showPassword ? 'text' : "password"}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                   onClick={handleClick}
                                   edge="end"
                                 >
                                   {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                 </IconButton>
                               </InputAdornment>
                            ) 
                          }}
                        />
                    )}
                />
                </div >
                <Grid >  
                    {errors.password && <small className="text-danger">{errors.password.message}</small>} 
                    {errors.password && errors.password.type === "validate" && (
                            <div className="text-danger">Mot de passe erroné</div>
                        )
                        }
                </Grid>
                </Grid> 

                <Grid container direction="row"  item xs={12} justifyContent="center" alignItems="center">
                 
                    <Button size='small' variant="contained" color="error" startIcon={<CancelOutlinedIcon/>} onClick={() =>
                        {
                            reset({Email:'',PassWord:''})
                            naviguer(-1)
                        }
                        }>  Abandonner 
                    </Button>
                        <Button size='small' variant="contained" color="primary" type="submit" startIcon={<LoginIcon/>}> Créer le compte</Button>
                    
                </Grid>
                
            </Grid>   
          </form>
        </div>
    )
}
export default CreateUser