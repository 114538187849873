import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SocialFollow.css'

import {
    faYoutube,
    faFacebook,
/*     faTwitter,
    faInstagram */
  } from "@fortawesome/free-brands-svg-icons";
  
export default function SocialFollow() {
  return (
    <div className="container">
    
    <div className="social-container">
      <a href="https://www.youtube.com/channel/UCrbalwfIMvkMrbOqgVkkhQg" className="youtube social"
      target="_blank" rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="youtube" icon={faYoutube} size="2x" />
      </a>
      <a href="https://web.facebook.com/Diocesendali-102366172538460/"
        className="facebook social" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      {/* <a href="https://www.twitter.com/jamesqquick" className="twitter social" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
      <a href="https://www.instagram.com/learnbuildteach"
        className="instagram social" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a> */}
    </div>
    </div>
  );
}
