import React ,{ useState,useEffect } from "react";
import { useForm, useFieldArray ,Controller} from "react-hook-form";
import { Button } from "react-bootstrap";
import axios from 'axios';
import { ClientUrl } from "../helpers/constantes";
import { ServerUrl } from "../helpers/constantes";
import ViewImagesSlides from "./ViewImagesSlides";
import AdminMenuBar from "./AdminMenuBar";
import Entete from "../Site/components/entete";
import ProtectContent from '../helpers/ProtectContent';
import EnteteAdminPage from "./EnteteAdminPage/EnteteAdminPage";


const  UploadSlideShowFilesForm =({param})=>{
    const { register, control, handleSubmit, reset, formState, watch } = useForm();
    const { errors } = formState;
    const {fields, append, remove} = useFieldArray({
      control,
      name: 'SlideShowDatas',
    });
    
    const [nbeRefresh,SetnbeRefresh]=useState(0)

    const fileExist = async(datas)=>{
        const {data}= await axios.post(`${ServerUrl}/api/slide/exist`, datas, )
        console.log('data nbre',data[0].nbre)
        return data[0].nbre 
    }
      
    const onSubmit = async (data) => {////////////////////
        console.log(")=====> onSubmit data ", data)
        var formData = new FormData();
        var descritionInfo=[]
        console.log("ici, avant axios.post, voici les fichiers : ",data.SlideShowDatas)
        data.SlideShowDatas.map((item,index)=>{
            //console.log(item)
            formData.append('slideSowFiles',item.filename[0])
            //console.log('description :',item.description)
            //formData.append('description',item.description)
            formData.append('fichier','images/slides/'+ item.filename[0].name)
            formData.append('description',item.description)
        })
        //console.log("descritionInfo= ",descritionInfo)
        // /slides-sftp
        // /slides-images
        axios.post(`${ServerUrl}/slides-images-serv`, formData, {
            headers: {
             "Content-Type": "multipart/form-data",
            // 'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            },
          })
        .then(() => { 
            console.log("Fin envoie")
                SetnbeRefresh(nbeRefresh+1) 
            })
    };////////////////////////////////////////////////////
    const mainTitre = {
        color: "white",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "brown",
        padding: "10px",
        fontFamily: "Arial"
      };
      useEffect(() => {
        async function fetchData() {
         let i=1;
        }
        fetchData();
      }, [nbeRefresh])  
    return (
        <>
        <div className="container">
                <Entete/>
            <AdminMenuBar/>
            <EnteteAdminPage/>
        <div className="row d-flex flex-row py-5">
                <div className="col-sm-6 col-md-6" >
                     <ViewImagesSlides/>
                </div>
                <div className="col-sm-6 col-md-6" >
                    <p className="text-light bg-success text-center"> Veuillez sélectionner les images en envoyer sur le server</p>
                    <form onSubmit={handleSubmit(onSubmit)} >
                    {fields.map((item, i) => 
                        (
                            <div className="row d-flex flex-row" key={i}>
                            <div className="list-group-item">
                                <h5 className="card-title">Fichier {i + 1}</h5>
                                <div className="form-row">
                                    <div className="form-group col-6">
                                        <label>Fichier</label>
                                        <input name={`SlideShowDatas[${i}]filename`} 
                                            {...register(`SlideShowDatas.${i}.filename`,
                                                 { required: "Veuillez choisir un fichier",                                                     
                                                   validate:async(value)=>{
                                                       console.log("value",value[0].name)
                                                       var un={filename:"images/slides/"+value[0].name}
                                                       let ex=await fileExist(un)
                                                       console.log ("ex ?",ex)
                                                       console.log ("ex!=1 ?",ex!=1)
                                                       console.log ("ex!==1 ?",ex!==1)
                                                       return ex!=1
                                                    }
                                                 },
                                                 )
                                            } 
                                            type="file" accept="image/*"
                                        />
                                        <div className="invalid-feedback">{errors.SlideShowDatas?.[i]?.filename?.message}</div>
                                        {errors.SlideShowDatas?.[i]?.filename  && <small className="text-danger">{errors.SlideShowDatas?.[i]?.filename .message}</small>} 
                                        {errors.SlideShowDatas?.[i]?.filename && errors.SlideShowDatas?.[i]?.filename.type === "validate" && (
                                            <div className="text-danger">Ce fichier a déjà été choisi</div>
                                        )
                                        }
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Description</label>
                                        <input name={`SlideShowDatas[${i}]description`} 
                                            {...register(`SlideShowDatas.${i}.description`, { required: "Veuillez saisir une description pour ce fichier" })} 
                                            type="text" className={`form-control ${errors.SlideShowDatas?.[i]?.description ? 'is-invalid' : ''}`} 
                                        />
                                        <div className="invalid-feedback">{errors.SlideShowDatas?.[i]?.description?.message}</div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-danger" onClick={() => remove(i)}>Supprimer ce fichier</button>
                        </div>
                        )
                    )
                    }
                <div className="row ">
                    <button className="btn btn-secondary mr-1"  type="button" onClick={() => append()}>Ajouter un Fichier</button>
                </div>
                
            <div className="row ">
                    <input className="btn btn-success mr-1" type="submit" />
            </div>
                
            </form>
                    
                </div>
        </div>
    </div>
    </>    
    )

}

export default ProtectContent(UploadSlideShowFilesForm)