import React ,{useState,useEffect} from "react";
import axios from "axios";
import Entete from "../components/entete";
import BlogArtCat from "../components/BlogArtCat";
import SildeShow from "../components/SildeShow";
import { ServerUrl } from "../../helpers/constantes";
//import YoutubeAppPlayList from "../components/YoutubePlayList/YoutubeAppPlayList";
import TestYoutubeforNdaliChannel from "../components/YoutubePlayList/TestYoutubeforNdaliChannel";
import EspaceCatechisme from "../components/EspaceKt/EspaceCatechisme";
import MenuBar from "../components/MenuBar";
import NewMenuBar from "../components/NewMenuBar/NewMenuBar";
import SocialFollow from "../components/Social/SocialFollow";
import './Home.scss'
const Home=()=>{
  const [ListImages,SetListImages]=useState([]);
    useEffect(() => {
        async function fetchData() {
         const {data}=await axios.post(`${ServerUrl}/api/slide/all`)
         SetListImages(data)
        }
        fetchData();
      }, [])

    return (
      <>
      <div className="container">
      <MenuBar/>
      <Entete/>
        <div className="row gx-0 d-flex flex-row">
          <div className="col-sm-7 col-md-7 no-gutters"><SildeShow ImagesInfos={ListImages}/> </div>
          <div className="col-sm-4 col-md-4 no-gutters"> <EspaceCatechisme/></div>
          <div className="col-sm-1 col-md-1 no-gutters"> <SocialFollow/></div>
        </div>
        <BlogArtCat catid={128} ArticlesPerPage={9}/>
        <TestYoutubeforNdaliChannel/>
      </div> 
    </>   
    )
}
export default Home;