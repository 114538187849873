import React from 'react'
import ImageForm from './ImageForm'
const ImagesChoiseForm = ({artId,images}) => {

  let ImagesInfo
  if (images) ImagesInfo=JSON.parse(images) 
  else {
    ImagesInfo={
      image_intro:'',
      image_fulltext:''
    }
  };
  console.log("ImagesInfo ",ImagesInfo)
  return (
    <>
        <div >
           <ImageForm name={"image_intro"} artId={artId} titre={"Image d'introduction"} image={ImagesInfo.image_intro}/>
        </div>
        <div>
           <ImageForm name={"image_fulltext"} artId={artId}  titre={"Image de l'article complet "} image={ImagesInfo.image_fulltext}/>
        </div>
    </>
  )
}
export default ImagesChoiseForm