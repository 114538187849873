export const SELECT_CHANNEL = 'SELECT_CHANNEL';
export const REQUEST_POSTS = 'REQUEST_POSTS';
export const RECEIVE_POSTS = 'RECEIVE_POSTS';
export const SET_DATA_INTO = 'SET_DATA_INTO';
export const SET_DATA_CORPS = 'SET_DATA_CORPS';
export const SET_ARTICLE_ID = 'SET_ARTICLE_ID';
export const SET_ARTICLE_TITLE = 'SET_ARTICLE_TITLE';
export const SET_ARTICLE_CATID = 'SET_ARTICLE_CATID';

export const SetDataIntro = (data_intro) =>  {
    return {
        type: SET_DATA_INTO,
        payload: data_intro,
    }
    };
export const SetDataCorps = data_corps => {
    return {
        type: SET_DATA_CORPS,
        payload:data_corps,
    }
    };
export const SetArticleId = article_id => {
    return {
        type: SET_ARTICLE_ID,
        payload:article_id,}
    };
export const SetArticleTitle = article_title => {
    return {
        type: SET_ARTICLE_TITLE,
        payload:article_title,}
    };
export const SetArticleCatid = article_Catid => {
    return {
        type: SET_ARTICLE_CATID,
        payload:article_Catid,}
    };
 