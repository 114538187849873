import React,{useState} from 'react';
import {TreeSelect } from 'antd';
import MenuItems from '../../Site/MenuItems.json'
import "antd/dist/antd.css";
import './TreeNodeCatSelect.css'
const {TreeNode } = TreeSelect;


const TreeNodeCatSelect =({treeData, value,onChange,placeholder}) => {
     const onNodeChange = values => {
        //console.log('values', values);
        onChange(values);
    };

    const renderTreeNodes = data =>
        data.map(item => {
            //console.log('renderTreeNodes item', item);
            if (item.submenu) {
                item.disabled = true;
                return (
                    <TreeNode key={ item.CatId} title={item.title} value={ item.CatId} selectable={ false}>
                        { renderTreeNodes(item.submenu)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} key={item.CatId} title={ item.title} value={ item.CatId} />;
        });

    return (
        <>
          Catégorie
        <TreeSelect showSearch className=''
            style={{ width: '100%' }}
            value={ value}
            dropdownStyle={{maxHeight: 400, overflow: 'auto' }}
            placeholder={placeholder}
            treeNodeFilterProp="label"
            allowClear
            multiple={false}
            treeDefaultExpandAll={false}
            listHeight={350}
            onChange={values => onNodeChange(values)}
        >
            {renderTreeNodes(treeData)}
        </TreeSelect>
        </>
    );
};

export default TreeNodeCatSelect