import { ServerUrl } from "../../helpers/constantes";
import axios from "axios";
export const AdminListeArticles= async (param ) => {
     const rep= await axios.post(`${ServerUrl}/api/contents/admlstart`,param)
     //console.log("/api/users/exist/ : ",rep.data[0].nbre)
   return rep;
 }

export const ArticleSetData= async (param ) => {
  console.log("ArticleSetData  ApiDb Call Param Reçu : ",param)
  const rep= await axios.post(`${ServerUrl}/api/contents/admpostartdata`,param)
return rep; 
}

export const ArticleSetCat= async (param ) => {
  //const rep= await axios.post(`${ServerUrl}/api/contents/admpostartdata`,param)
  //console.log("/api/users/exist/ : ",rep.data[0].nbre)
return //rep;
}

export const SaveNewArticle = async (param ) => {
  const rep= await axios.post(`${ServerUrl}/api/contents/savenew`,param);
  return rep; 
}
export const delArticle = async (param ) => {
  const rep= await axios.post(`${ServerUrl}/api/contents/del-art`,param);
  return rep; 
}