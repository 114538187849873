import React ,{useRef,useState,useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { TextField,InputAdornment, IconButton,Button,Grid } from '@mui/material';
import { UserExists, GetUserByEmail,SeConnecter} from '../../ApiForDB/user.api';
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoginIcon from '@mui/icons-material/Login';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Entete from '../components/entete'
import './LogInForm.scss'



const LogInForm = () => {
   
    const naviguer=useNavigate()
    const UserInfo=useRef({}) 
    const TheToken=localStorage.getItem("se") 
    const [showPassword, setShowPassword] = useState(false);
    const [MainMessageError,setMainMessageError] =useState(null) 
    const handleClick = () => {
        setShowPassword(prev => !prev);
      }
    
    const [currentUser,SetcurrentUser]  = useState(sessionStorage.getItem('currentUser') )
    
    useEffect(() => {
       // console.log("currentUser ",JSON.parse(currentUser))
        if (JSON.parse(currentUser)) naviguer("/admin", { state: JSON.parse(currentUser) })
       }, [currentUser])
       

    const { handleSubmit,reset, formState: { errors },control,   } = useForm({mode:'onTouched'});  
    
    const onSubmit=async data => {
       //console.log("Le formulaire est validé. ",data)
       const re= await SeConnecter(data)
       if (! re.valid) {
        setMainMessageError("Mot de passe erroné")
       } else {
           //console.log(" L'utilisateur s'est connecté avec succès ")
           setMainMessageError(null)
           sessionStorage.setItem('currentUser', JSON.stringify(re.user))
           SetcurrentUser(JSON.stringify(re.user))
           naviguer("/admlst", { state: re.user })
        }
      // console.log(re)
      }


    return (
        <div className="mainfrom container" >
             <Entete/>
            <div className="bg-white" style={{ width: 350 }} >
               

            {!TheToken &&
          <form onSubmit={handleSubmit(onSubmit)}> 
            <Grid container  direction="column" justifyContent="center" alignItems="center">
                <Grid container  direction="row" justifyContent="center" alignItems="center">
                     <h3 className='border-b text-center mb-3 pb-3 font-bold' >Veuillez vous connecter</h3>
                </Grid >
                
                 {
                    MainMessageError && <h3 className='text-danger border-b text-center mb-3 pb-3 font-bold' >
                        {MainMessageError}
                    </h3>
                 }
                
                <Grid container item xs={12}  direction="column" justifyContent="center" alignItems="flex-start">
                <Grid ><Controller
                    name="email"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur' ,
                        validate:async(value)=>{
                            let resultat=await UserExists(value)
                             return resultat === 1
                        }
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: {  isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }}> <MailOutlineIcon/> Email </span>}
                        onChange={onChange} value={value}
                        onBlur={onBlur}
                        
                        />
                    )}
                /></Grid >
                
                    <Grid >
                        {errors.email && <small className="text-danger">{errors.email.message}</small>} 
                        {errors.email && errors.email.type === "validate" && (
                            <div className="text-danger">Ce Email n'existe pas</div>
                        )
                        }
                    </Grid>
                </Grid> 
                <Grid container item xs={12} direction="column" justifyContent="center" alignItems="flex-start">
                <Grid >
                <Controller
                    name="password"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur',
                        validate:(value)=>{
                            if (UserInfo.current.exist )
                            {
                                return (UserInfo.current.item[0].Userpwdl===value)
                            }
                        }
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }} ><VpnKeyIcon/>Mot de passe </span>}
                        onChange={onChange} value={value}
                        type={showPassword ? 'text' : "password"}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                   onClick={handleClick}
                                   edge="end"
                                 >
                                   {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                 </IconButton>
                               </InputAdornment>
                            ) 
                          }}
                        />
                    )}
                />
                </Grid >
                <Grid >  
                    {errors.password && <small className="text-danger">{errors.password.message}</small>} 
                    {errors.password && errors.password.type === "validate" && (
                            <div className="text-danger">Mot de passe erroné</div>
                        )
                        }
                </Grid>
                </Grid> 

                <Grid container  item xs={12} justifyContent="center">
                    <Grid item xs={6} >
                    <Button size='small' variant="contained" color="error" startIcon={<CancelOutlinedIcon/>} onClick={() =>
                        {
                            reset({Email:'',PassWord:''})
                            naviguer("/")
                        }
                        }>  Abandonner 
                    </Button>
                    </Grid>
                    <Grid item xs={6} >
                        <Button size='small' variant="contained" color="primary" type="submit" startIcon={<LoginIcon/>}> Connecter</Button>
                    </Grid>
                </Grid>
                
            </Grid>   
          </form>}
          </div>
      
          </div>
    
    )
}

export default LogInForm
